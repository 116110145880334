import React, { useState } from 'react';
import './AirdropModal.css'; // Custom styles
import '@fortawesome/fontawesome-free/css/all.min.css';


function AirdropModal() {
  const [isVisible, setIsVisible] = useState(true);

  // Close the modal when the user clicks "I'm already registered" or "Register now"
  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="modal-overlay">
        <div className="modal-content">
        <div className="icon-wrapper">
            <i className="fas fa-gift shaking"></i>
          </div>
          <h2> Airdrop is Live!</h2>
          <p>
            We’re giving away tokens as part of our integration process! <br></br>
            To qualify for this airdrop, you need to register using our Telegram bot (send /start). <br></br>
            Don't miss out on your chance to participate and earn exclusive rewards.<br></br>
            Use any of our availabe tools to qualify.
          </p>
          <div className="button-group">
          
            <a href="https://t.me/turbofun_bot" target="_blank" rel="noopener noreferrer">
              <button className="modal-button gradient" style={{borderRadius:'15px'}}>
                Register now
              </button>
            </a>
            <button className="modal-button gradient" style={{borderRadius:'15px'}} onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default AirdropModal;

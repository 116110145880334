import React from 'react';
import './Roadmap.css'; // Add this for external styling

const Roadmap = () => {
  const quarters = [
    { label: "Q1 2024", filled: true, description: "Initial development phase, setting up core API features and foundation." },
    { label: "Q2 2024", filled: true, description: "Testing and development of first tools." },
    { label: "Q3 2024", filled: true, description: "Launch of platform, basic tools and Airdrop program for early users." },
    { label: "Q4 2024", filled: false, description: "Launch of full platform and ecosystem, backed by our native token." },
    { label: "Q1 2025", filled: false, description: "Further platform expansion, adding new tools and user suggestions." },
    { label: "Q2 2025", filled: false, description: "Full ecosystem sustainability reached" }
  ];

  return (
    <div id="roadmap" className="roadmap-container" >
      <h1 className='gradient-text'>Roadmap</h1>
      <div className="timeline">
        {quarters.map((quarter, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-content">
              <div
                className={`timeline-circle ${quarter.filled ? 'filled' : 'unfilled'}`}
              ></div>
              <div className="timeline-text">
                <div className="timeline-label">{quarter.label}</div>
                <div className="timeline-description">{quarter.description}</div>
              </div>
            </div>
            {index < quarters.length - 1 && (
              <div className={`timeline-line ${quarters[index + 1].filled ? 'filled' : 'unfilled'}`}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;

import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/turboFunIcon.png'; // Adjust this path based on your folder structure

const LogoContainer = styled.div`
  position: relative;
  top: 5px;
  left: 0%;
  z-index: 100; /* Ensure it appears above other elements */
`;

const LogoImage = styled.img`
  height: 65px; /* Adjust size as needed */
`;

const Logo = () => {
  return (
      <LogoImage src={logo} alt="TurboFun Logo" />
  );
};

export default Logo;

import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import useScrollAnimation from '../hooks/useScrollAnimation';
import './utils.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
const HeroContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent; /* Make sure sections are transparent to show body gradient */
  color: white;
  padding: 0 20px;
 @media (max-width: 768px) {
    margin-top:40%
  }
`;


const WelcomeText = styled(motion.h1)`
  font-size: 3.5rem;
  color: #e0e0e0;
  margin-top:10%;
  text-align:center;
  margin-bottom: 20px;
  line-height: 1.2;
`;

const TurboFunText = styled(motion.span)`
  background: linear-gradient(90deg, #4ce0d2, #8800ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  
  font-weight: bold;
`;

const Description = styled(motion.p)`
  font-size: 1.2rem;
  color: #e0e0e0;
  margin-top: 20px;
  text-align: center;
  line-height: 1.6;
  max-width: 600px;
`;

const GradientButton = styled(motion.button)`
  background: linear-gradient(135deg, #8800ff, #a64dff);
  border: none;
  padding: 15px 40px;
  border-radius: 50px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  margin-top: 40px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  &:hover {
    background: linear-gradient(135deg, #a64dff, #8800ff);
    transform: translateY(-3px);
  }
`;

const HeroSection = () => {
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const isHeadingVisible = useScrollAnimation(headingRef);
  const isDescriptionVisible = useScrollAnimation(descriptionRef);

  return (
    <HeroContainer>
      <WelcomeText
        ref={headingRef}
        initial={{ opacity: 0, x: -100 }}
        animate={isHeadingVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
        transition={{ duration: 1 }}
      >
        Welcome to <br />
        <TurboFunText>turbofun.io</TurboFunText>
      </WelcomeText>

      <Description
        ref={descriptionRef}
        initial={{ opacity: 0, y: 50 }}
        animate={isDescriptionVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 1.5 }}
      >
        The all-in-one Solana toolset for devs and traders<br></br> powered by our ecosystem.
      </Description>

      <GradientButton
        as="a"
        href='/whitepaper.pdf'
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >

        Learn more 
        <FontAwesomeIcon icon={faArrowUpRightFromSquare}></FontAwesomeIcon>
      </GradientButton>
      
      <div className='separator-line'></div>
    </HeroContainer>
  );
};

export default HeroSection;

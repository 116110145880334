import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaTelegram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: #1b1b2f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
`;

const FooterLink = styled.a`
  color: white;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: #8800ff;
    transform: scale(1.1);
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLink href="https://twitter.com/turbofun_io"><FaTwitter /></FooterLink>
      <FooterLink href="https://t.me/turbodotfun"><FaTelegram /></FooterLink>
    </FooterContainer>
  );
};

export default Footer;

import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';
import logo from '../assets/images/turboFunIcon.png'; // Adjust the path to your actual logo file

// Registering Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

// Custom plugin to add logo in the center of the chart
const logoPlugin = {
  id: 'logoInCenter',
  beforeDraw: (chart) => {
    const { ctx, width, height } = chart;
    const image = new Image();
    image.src = logo;  // Use your logo image
    const xPos = width / 2 - 50;  // Adjust as per logo size
    const yPos = height / 2 - 50; // Adjust as per logo size
    ctx.drawImage(image, xPos, yPos, 100, 100); // Draw the logo in the center
  },
};

const TokenomicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  min-height: 100vh;
  background-color: #0a0f1a;
  color: #e0e0e0;
  border-radius: 15px;
  margin: 50px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const TokenomicsTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const PieChartContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const SectionContainer = styled.div`
  margin-top: 50px;
  text-align: left;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  background-color: rgba(45, 45, 68, 0.5);
  border-radius: 10px;
    box-shadow: 0 2px 30px rgba(136, 0, 255, 0.3);    overflow: hidden;
`;

const SectionLabel = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: center;
  color: #8800ff;
`;

const SectionDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #e0e0e0;
  text-align: center;
`;

const data = {
  labels: ['Team & Partners', 'Marketing, Development & Other Costs', 'Airdrop', 'Initial Ecosystem Liquidity', 'Tokens for Sale'],
  datasets: [
    {
      label: 'Token Distribution',
      data: [20, 20, 5, 5, 50],
      backgroundColor: [
        '#8800ff',  // Dark purple
        '#a64dff',  // Medium purple
        '#bf80ff',  // Lighter purple
        '#d1a3ff',  // Soft lavender
        '#e0c1ff',  // Light purple
      ],     
      borderColor: ['#1b1b2f', '#1b1b2f', '#1b1b2f', '#1b1b2f', '#1b1b2f'],
      borderWidth: 2,
      hoverOffset: 10,  // Enlarge the slice on hover
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#e0e0e0',
      },
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          const value = tooltipItem.raw;
          const label = tooltipItem.label;
          return `${label}: ${value}%`;
        },
      },
      backgroundColor: '#8800ff',
      titleColor: '#fff',
      bodyColor: '#fff',
      bodyFont: {
        size: 14,
      },
      padding: 10,
      cornerRadius: 10,
    },
  },
};

const Tokenomics = () => {
  return (
    <TokenomicsContainer id="tokenomics">
      <TokenomicsTitle>Tokenomics</TokenomicsTitle>

      <PieChartContainer>
        <Pie data={data} options={options} height={250} plugins={[logoPlugin]} />
      </PieChartContainer>

      {/* Team & Partners Section */}
      <SectionContainer id="team-partners">
        <SectionLabel>Team & Partners - 20%</SectionLabel>
        <SectionDescription>
          These tokens are reserved for the team and strategic partners of turbofun.io to ensure long-term commitment and growth.
        </SectionDescription>
      </SectionContainer>

      {/* Marketing & Development Section */}
      <SectionContainer id="marketing-development">
        <SectionLabel>Marketing & Development - 20%</SectionLabel>
        <SectionDescription>
          This allocation will be used to fund marketing efforts to grow the community and improve project visibility.
        </SectionDescription>
      </SectionContainer>

      {/* Airdrop Section */}
      <SectionContainer id="airdrop">
        <SectionLabel>Airdrop - 5%</SectionLabel>
        <SectionDescription>
          These tokens are reserved for Airdrop eligible users who have participated in our Airdrop program. 
        </SectionDescription>
      </SectionContainer>

      {/* Initial Ecosystem Liquidity Section */}
      <SectionContainer id="ecosystem-liquidity">
        <SectionLabel>Initial Ecosystem Liquidity - 5%</SectionLabel>
        <SectionDescription>
          These tokens are allocated to ensure initial liquidity to our ecosystem's pool.
        </SectionDescription>
      </SectionContainer>

      {/* Tokens for Sale Section */}
      <SectionContainer id="tokens-sale">
        <SectionLabel>Tokens for Sale - 50%</SectionLabel>
        <SectionDescription>
          The largest portion is reserved for public token sales on DEXs and exchanges.
        </SectionDescription>
      </SectionContainer>
    </TokenomicsContainer>
  );
};

export default Tokenomics;

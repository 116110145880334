// ToolsDisplay.js
import React from 'react';
import ToolCard from './ToolCard';
import bumpBotImage from '../assets/images/bumpBotShowcaseImage.png'
import backgroundColor from '../assets/images/defaultBackgroundShowcase.png'
import './PumpFunTools.css'
import ComingSoonCard from './ComingSoonCard';
const toolsData = [
  {
    image: bumpBotImage, // Replace with your image path
    name: "Bump Bot",
    description: "Bump your tokens to the main page on pump.fun",
    features: [" Multi-Wallet transactions", " Bundled buy/sell in same transaction", " Costumizable transaction delay", " Randomized buy amounts"],
    pricing: "0.05 SOL per bot"
  },
];

const PumpFunTools = () => {
  return (
    <div className="tools-display">
      {toolsData.map((tool, index) => (
        <ToolCard
          key={index}
          image={tool.image}
          name={tool.name}
          description={tool.description}
          features={tool.features}
          pricing={tool.pricing}
        />
      ))}
        <ComingSoonCard
        image={backgroundColor}
        name="Comment Bot"
        description="Automatically shill your project on pump.fun threads"
        features={[" Custom messages", " Comment on newly launched tokens", " Multi-wallet", " Shill your project with maximum reach"]}
        pricing="Pricing: TBD"
      />
      <ComingSoonCard
        image={backgroundColor}
        name="Launch manager"
        description="Launch and manage all of your tasks and wallets from a single menu"
        features={[" Bundle initial buys for better supply control", " Manage buys/sells for all wallets", " Auto volume bot to create organic volume", " Analytics for each launch"]}
        pricing="Pricing: 0.1 SOL per launch"
      />
    </div>
  );
};

export default PumpFunTools;


import { API_ENDPOINT_PROD } from "./constants";
export async function getUserSpentSol (apiKey){
    try {
        const data = {apiKey : apiKey}
        const response = await fetch(`${API_ENDPOINT_PROD}userAirdropSolSpent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.status===200 || response.status===401 || response.status===400 || response.status === 404) {
            return false;
        }

        const result = await response.json();
        if(response.status===200){return result.SOL_SPENT}
    } catch (error) {
        console.error('Error while making API request: ', error);
        return false;
    }
}
// ToolsDisplay.js
import React from 'react';
//import ToolCard from './ToolCard';
import backgroundColor from '../assets/images/defaultBackgroundShowcase.png'
import './RaydiumTools.css'
import ComingSoonCard from './ComingSoonCard';

const RaydiumTools = () => {
  return (
    <div className="tools-display">

        <ComingSoonCard
        image={backgroundColor}
        name="Rank Bot"
        description="Get your token on DEX Screener trending automatically"
        features={[" Over 3k new holders/makers", " Over 10k transactions", " Fast delivery"]}
        pricing="Minimum deposit: 2 SOL"
      />
 <ComingSoonCard
        image={backgroundColor}
        name="Volume Bot"
        description="Generate organic volume for your raydium project with new holders"
        features={[" Different Volume options based on deposit", " Unpredictable buy/sell pattern to look human", " Fast delivery"]}
        pricing="Minimum deposit: 1 SOL"
      />
    </div>
  );
};

export default RaydiumTools;

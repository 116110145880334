import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tokenomics from './components/Tokenomics';
import Roadmap from './components/Roadmap';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import './App.css'
import Ecosystem from './components/Ecosystem';
import Airdrop from './components/Airdrop';
import PumpFunTools from './components/PumpFunTools';
import RaydiumTools from './components/RaydiumTools';
import MoonshotTools from './components/MoonshotTools';
import OtherTools from './components/OtherTools';
import AirdropModal from './components/AirdropModal';  // Import the modal

function App() {
  return (
    <Router>
      <Header />
      <AirdropModal />  {/* Add the Airdrop Modal here */}
      <Routes>
        <Route path="/" element={
          <>
            <HeroSection />    
            <Roadmap />       
          </>
        } />
        <Route path="/tokenomics" element={<Tokenomics />} />
        <Route path="/pump-fun" element={<PumpFunTools />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path='/raydium' element={<RaydiumTools />} />
        <Route path='/moonshot' element={<MoonshotTools />} />
        <Route path='/other' element={<OtherTools />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

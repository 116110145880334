import React, { useState, useEffect } from 'react';
import { Events } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faGift, faArrowRight, faTimes, faBars} from '@fortawesome/free-solid-svg-icons'; 
import Logo from './Logo';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;  /* Ensure the logo and toggle button are spaced correctly */
  align-items: center;
  width: fit-content;  /* Keep the width minimal on larger screens */
  padding: 10px 30px;
  height: 70px;
  position: fixed;
  top: ${({ isVisible }) => (isVisible ? '20px' : '-100px')};
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: rgba(45, 45, 68, 0.3);
  border-radius: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  transition: top 0.3s ease-in-out, background-color 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;  /* On mobile, make the header full width */
    padding: 10px 15px;  /* Adjust padding for mobile */
    height: auto;
    border-radius: 0;
    justify-content: space-between;
    top: 0;  /* Align to top on mobile */
    left: 0;
    transform: none;  /* Remove the translate on mobile */
  }
`;

const NavMenu = styled.nav`
  display: flex;
  gap: 30px;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: rgba(45, 45, 68, 0.9);
    padding: 20px 0;
    z-index: 99;
  }
`;

const ToggleButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    z-index: 101;
    position: relative;  /* Align it next to the logo */
  }
`;
const NavLink = styled.a`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  padding: 10px 0;
  transition: all 0.3s ease;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${({ isActive }) => (isActive ? '100%' : '0')};
    height: 2px;
    background-color: #8800ff;
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    color: #8800ff;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #222;
  min-width: 180px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e0e0e0;
    padding: 12px 16px;
    text-decoration: none;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
    position: relative;

    &:hover {
      background: linear-gradient(135deg, #8800ff, #a64dff);
      padding-left: 20px;
    }

    &:after {
      content: '→';
      font-size: 1rem;
      transition: transform 0.3s ease;
    }

    &:hover:after {
      transform: translateX(5px);
    }
  }
`;




const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Ensure logo is aligned to the left */
  margin-right: 20px;
  margin-top:5px;
  @media (max-width: 768px) {
    justify-content: flex-start;  /* Make sure the logo stays on the left */
    margin-right: auto;  /* Make sure it doesn't interfere with the toggle button */
  }
`;

const AirdropButton = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  background: linear-gradient(135deg, #8800ff, #a64dff);
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 25px;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(135deg, #a64dff, #8800ff);
    transform: translateY(-3px);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .button-text {
    display: block;
  }

  @media (max-width: 768px) {
    padding: 10px;
    margin-left: 0;
    
    .button-text {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const GradientButton = styled.a`
  background: linear-gradient(135deg, #8800ff, #a64dff);
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: linear-gradient(135deg, #a64dff, #8800ff);
    transform: translateY(-3px);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .button-text {
    display: block;
  }

  @media (max-width: 768px) {
    padding: 10px;
    
    .button-text {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const Header = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [activeSection, setActiveSection] = useState('');
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      setIsVisible(isScrollingUp || currentScrollPos < 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {});
    Events.scrollEvent.register('end', (to) => {
      setActiveSection(to);
    });

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const isRouteActive = (route) => location.pathname === route;

  return (
    <HeaderContainer isVisible={isVisible}>
      <LogoContainer>
        <Logo />
        <ToggleButton onClick={toggleMenu}>
        {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </ToggleButton>
      </LogoContainer>

      

      <NavMenu isOpen={isMenuOpen}>
        <RouterLink to="/">
          <NavLink isActive={isRouteActive('/')}>Home</NavLink>
        </RouterLink>
      
        {/* Tools Dropdown */}
        <Dropdown>
          <NavLink>Tools</NavLink>
          <DropdownContent className="dropdown-content">
            <RouterLink to="/pump-fun">Pump.fun</RouterLink>
            <RouterLink to="/raydium">Raydium</RouterLink>
            <RouterLink to="/moonshot">Moonshot</RouterLink>
            <RouterLink to="/other">Other</RouterLink>
          </DropdownContent>
        </Dropdown>
        {/* $turbofun Dropdown */}
        <Dropdown>
          <NavLink>$turbofun</NavLink>
          <DropdownContent className="dropdown-content">
            <RouterLink to="/tokenomics">Tokenomics</RouterLink>
            <RouterLink to="/ecosystem">Ecosystem</RouterLink>
          </DropdownContent>
        </Dropdown>
        <NavLink as="a" href="/whitepaper.pdf" target="_blank" rel="noopener noreferrer">
          Whitepaper
        </NavLink>
      </NavMenu>

      <ButtonGroup>
        <AirdropButton to="/airdrop">
          <FontAwesomeIcon icon={faGift} />
          <span className="button-text">Airdrop</span>
        </AirdropButton>
        <NavLink as="a" href="https://wip.turbofun.io" target="_blank" rel="noopener noreferrer">
          <GradientButton>
            <span className="button-text">Launch App</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </GradientButton>
        </NavLink>
      </ButtonGroup>
    </HeaderContainer>
  );
};

export default Header;

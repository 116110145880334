// ToolsDisplay.js
import React from 'react';
//import ToolCard from './ToolCard';
import backgroundColor from '../assets/images/defaultBackgroundShowcase.png'
import './MoonshotTools.css'
import ComingSoonCard from './ComingSoonCard';
/*
        <ComingSoonCard
        image={backgroundColor}
        name="Sniper Bot"
        description="Automatically snipe new launches and try to sell for a profit"
        features={[" Fastest moonshot sniper", " Costumizable settings", " Fast delivery"]}
        pricing="Minimum deposit: 2 SOL"
      />
      */
const MoonshotTools = () => {
  return (
    <div className="tools-display">

 <ComingSoonCard
        image={backgroundColor}
        name="Volume Bot"
        description="Generate organic volume for your moonshot project with new holders"
        features={[" Different Volume options based on deposit", " Unpredictable buy/sell pattern to look human", " Fast delivery"]}
        pricing="0.1 SOL per bot + fees"
      />
    </div>
  );
};

export default MoonshotTools;

// ToolsDisplay.js
import React from 'react';
//nnpimport ToolCard from './ToolCard';

import backgroundColor from '../assets/images/defaultBackgroundShowcase.png'
import './OtherTools.css'
import ComingSoonCard from './ComingSoonCard';
/*
        <ComingSoonCard
        image={backgroundColor}
        name="Sniper Bot"
        description="Automatically snipe new launches and try to sell for a profit"
        features={[" Fastest moonshot sniper", " Costumizable settings", " Fast delivery"]}
        pricing="Minimum deposit: 2 SOL"
      />
      */
const OtherTools = () => {
  return (
    <div className="tools-display">

 <ComingSoonCard
        image={backgroundColor}
        name="Safe-trading Bot"
        description="Trading bot incorporated with safety features to make your degen trading safer and avoid rugs"
        features={[" Rug and Top-Holder check", " Turbo-fast transactions", " Low fees", " Supports: Pump.fun, Raydium, Moonshot etc."]}
        pricing="1.5% fees on every transaction"
      />
    </div>
  );
};

export default OtherTools;

import React from 'react';
import styled from 'styled-components';

const EcosystemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #0a0f1a;
  color: #e0e0e0;
  border-radius: 15px;
  margin: 50px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 40px;
`;

const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top : 40px;
  align-items: center;
`;

const Node = styled.div`
  background-color: #8800ff;
  color: white;
  border-radius: 50px;
  min-width: 200px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  font-size: 1.2rem;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 40px;
    background-color: white;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &:last-child:after {
    display: none; /* Hide line for the last node */
  }
`;

const BranchContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
`;

const Branch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 40px;
    background-color: white;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:first-child:before {
    left: 75%; /* Adjust position for first child */
  }

  &:last-child:before {
    left: 25%; /* Adjust position for last child */
  }
`;

// Description Styling
const DescriptionContainer = styled.div`
  background-color: #1e1e1e;
  border: 2px solid #8800ff;
  border-radius: 10px;
  padding: 30px;
  max-width: 800px;
  margin: 40px auto 0;
  text-align: left;
    box-shadow: 0 12px 30px rgba(136, 0, 255, 0.3);    overflow: hidden;
`;

const DescriptionTitle = styled.h2`
  font-size: 1.8rem;
  color: #a64dff;
  text-align: center;
  margin-bottom: 15px;
`;

const DescriptionText = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #e0e0e0;
`;

const TreeDiagram = () => {
  return (
    <TreeContainer>
      {/* Root node (100% Revenue) */}
      <Node>100% of earned Revenue (tokens tax, bot sales etc.)</Node>

      {/* Branches */}
      <BranchContainer>
        <Branch>
          <Node>(10%) Burned</Node>
        </Branch>
        <Branch>
          <Node>(50%) Added to LP</Node>
        </Branch>
        <Branch>
          <Node>(40%)Project Costs</Node>
        </Branch>
      </BranchContainer>
    </TreeContainer>
  );
};

const Ecosystem = () => {
  return (
    <EcosystemContainer>
      
      <Title>Ecosystem</Title>

      {/* Description section */}
      <DescriptionContainer>
        <DescriptionTitle>The purpose of our ecosystem</DescriptionTitle>
        <DescriptionText>
          Our ecosystem is backed by its native token, $turbofun, used for in-app transactions and getting access to features and perks in our platform.
          <br/>          <br/>

          It was developed not only as a way of guaranteeing token price and valorization, but also as a way of making our tools cheaper and sustainable, powering the entire toolchain.
            <br/>            <br/>

            The ecosystem is deflationary and ensures a annual valorization through consistent burns and token-locks.
        </DescriptionText>
      </DescriptionContainer>
      <DescriptionContainer>
        <DescriptionTitle>How it Works</DescriptionTitle>
        <DescriptionText>
          
          All generated revenue is distributed strategically across three key areas:
          <br />
          <br />

          <strong> 10% Burned:</strong> A portion of the tokens generated in revenue are permanently removed from circulation, reducing the total supply and helping maintain the value of the remaining tokens.
          <br />
          <br />
          <strong>50% Added to LP:</strong> Half of the revenue is used to buy and lock tokens, adding value to the LP.
          <br />          <br />

          <strong>40% Project Costs:</strong> A final portion is allocated to cover ongoing project expenses, including development, marketing, and server maintenance.

        </DescriptionText>
        *Please note that these values may change to ensure sustainability and growth*
      </DescriptionContainer>
      <TreeDiagram />
    </EcosystemContainer>
  );
};

export default Ecosystem;

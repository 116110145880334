import React, { useState, useEffect } from 'react';
import './Airdrop.css';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { getUserSpentSol } from './helpers/apiCalls';
import { Connection, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';

// Styled components
const Airdrop = () => {
    const [apiKey, setApiKey] = useState('');
    const [userTokens, setUserTokens] = useState(0);
    const [reservedTokens, setReservedTokens] = useState(0); // For progress bar
    const [showModal, setShowModal] = useState(false);
    const [howToParticipateModal, setHowToParticipateModal] = useState(false);
    const tokensPerSol = 1000000; //1 sol = 1M tokens (50 sol = 50M)

    const DescriptionContainer = styled.div`
        background-color: #292941;
        border: 2px solid #8800ff;
        border-radius: 10px;
        padding: 30px;
        max-width: 800px;
        margin: 150px auto 0;
        text-align: center;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    `;
  
    const GradientText = styled(motion.span)`
        background: linear-gradient(90deg, #4ce0d2, #8800ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.2rem;
        font-weight: bold;
    `;
  
    const DescriptionTitle = styled.h2`
        font-size: 1.8rem;
        color: #a64dff;
        text-align: center;
        margin-bottom: 15px;
    `;
  
    const DescriptionText = styled.p`
        font-size: 1.2rem;
        line-height: 1.6;
        color: #e0e0e0;
    `;

    // New structure for content list container
    const ContentListContainer = styled.div`
        background-color: #292941;
        border: 2px solid #8800ff;
        border-radius: 15px;
        padding: 25px;
        max-width: 800px;
        margin: 20px auto;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    `;

    const ContentTitle = styled.h3`
        font-size: 1.6rem;
        color: #8800ff;
        margin-bottom: 20px;
        text-align: center;
    `;

    // Two distinct containers below the title
    const SubContainer = styled.div`
        background-color: #1e1e30;
        padding: 20px;
        border-radius: 10px;
        margin-bottom: 15px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    `;

    const SubText = styled.p`
        font-size: 1.2rem;
        color: #e0e0e0;
        margin: 0;
        line-height: 1.5;
    `;
    const HowToParticipateButton = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #8800ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background 0.3s ease;

    &:hover {
        background-color: #a64dff;
    }

    span {
        margin-left: 8px;
        font-size: 1.5rem;
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #292941;
    padding: 30px;
    border-radius: 15px;
    max-width: 500px;
    text-align: center;
    color: #e0e0e0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);

    h2 {
        color: #8800ff;
        margin-bottom: 20px;
    }

    p {
        margin: 15px 0;
        font-size: 1.2rem;
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #8800ff;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
            background-color: #a64dff;
        }
    }
`;

const ContactButton = styled.a`
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #8800ff;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
        background-color: #a64dff;
    }
`;
const checkEligibility = async () => {
    if (apiKey) {
        //tokens earned = tokens per sol * sol spent
        let solSpent = await getUserSpentSol(apiKey);
        setUserTokens(tokensPerSol*parseFloat(solSpent));
        if(tokensPerSol*parseFloat(solSpent) > 0) {
            setShowModal(true);
        }else{
            alert("Invalid API Key or account not eligible");
        }
    } else {
        alert("Please enter a valid API Key.");
    }
};

const getTokensReserved = async () => {
    let rpcUrlConnection = new Connection("https://robbi-meu04j-fast-mainnet.helius-rpc.com", 'confirmed');
    let solBalanceAirdrop = await rpcUrlConnection.getBalance(new PublicKey("6482gLBZZMj9DLMvhFfrcEXEB1zjh6d8jRyWPy7MSi9u"), 'confirmed');
    setReservedTokens(solBalanceAirdrop/LAMPORTS_PER_SOL*tokensPerSol)
   // return (solBalanceAirdrop/LAMPORTS_PER_SOL)*1000000; //sol spent total * tokens per sol (1M);
}
useEffect(() => {
    // Call the async function inside the effect
    const fetchTokens = async () => {
      await getTokensReserved();
    };

    fetchTokens(); // Execute the async function

  }, []); // Empty array means this effect runs once on mount

return (
    <section className="airdrop-container">
        {/* Airdrop Description */}
     
        <DescriptionContainer>
        
            <DescriptionTitle>How does the Airdrop work?</DescriptionTitle>
            <DescriptionText>
                The <GradientText>$turbofun</GradientText> airdrop serves as a way of engaging with the community in order to test some of our tools before committing to a full release while rewarding early users.
            </DescriptionText>
            <HowToParticipateButton onClick={() => setHowToParticipateModal(true)}>
            How to Participate <span>?</span>
        </HowToParticipateButton>
        </DescriptionContainer>

        {/* How to Participate Button */}
        

        {/* How to Participate Modal */}
        {howToParticipateModal && (
            <Modal>
                <ModalContent>
                    <h2>Follow these steps to be eligible:</h2>
                    <p><strong>Step 1: </strong>Join our <a href='https://t.me/turbodotfun'>Telegram (click here)</a></p>
                    <p><strong>Step 2:</strong> Send /start to our Telegram bot to get your API key (one per user)</p>
                    <ContactButton style={{marginBottom:'10px', marginTop:'5px'}} href="https://t.me/turbofun_bot" target="_blank">Contact Bot</ContactButton>
                    <p><strong>Step 3:</strong> Log into the app from the "Launch App" button with your API Key</p>
                    <p><strong>Step 4:</strong> Use any of the currently available tools for a cheap price.</p>
                    <p><strong>Step 5:</strong> Wait for the launch and Claim your tokens.</p>
                    <button onClick={() => setHowToParticipateModal(false)}>Close</button>
                </ModalContent>
            </Modal>
        )}

        {/* Content List Container */}
        <ContentListContainer>
            <ContentTitle>During Airdrop Phase:</ContentTitle>
            <SubContainer>
                <SubText>Tool prices are reduced.</SubText>
            </SubContainer>
            <SubContainer>
                <SubText>The SOL spent by each user on tools reverts into a bigger piece of the total Airdrop reserved tokens.</SubText>
            </SubContainer>

        </ContentListContainer>

        {/* Progress Bar */}
        <h2 style={{marginBottom: '-30px', marginTop:'30px'}}>{(reservedTokens / 50000000).toFixed(3) * 100}% of Tokens Reserved  </h2>
        <h3></h3>
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${(reservedTokens / 50000000) * 100}%` }}>
                <span>{parseInt(reservedTokens.toString()).toLocaleString()}/{parseInt(50000000).toLocaleString()}</span>
            </div>
        </div>

        {/* Check Eligibility */}
        <div className="eligibility-container">
            <input 
                type="text" 
                placeholder="Enter your API Key" 
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)} 
            />
            <button onClick={checkEligibility}>Check Eligibility</button>

        </div>

        {/* Modal for showing the share */}
        {showModal && (
            <Modal>
                <ModalContent>
                    <h2 className='success-text'>Congrats! your account is eligible.</h2>
                    <p>So far, you have reserved  <strong>{parseInt(userTokens/1000)}k</strong> $turbofun</p>
                    <button onClick={() => setShowModal(false)}>Close</button>
                </ModalContent>
            </Modal>
        )}
    </section>
);
};

export default Airdrop;